









import sink from '@/sink';
import { defineComponent, onMounted } from '@vue/composition-api';
import EnrollmentDetailsCard from '@conversa/bedazzled/src/cards/EnrollmentDetails/EnrollmentDetailsCard.vue';
import {
  EnrollmentChangeStatusClicked,
  EnrollmentEditClicked,
  EnrollPatientClicked,
  PatientEnrollmentInformationMounted,
} from './+state/events';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';
import EnrollmentChangeStatusDialogVue from './dialogs/EnrollmentChangeStatusDialog.vue';

export default defineComponent({
  components: { EnrollmentDetailsCard },
  setup() {
    onMounted(sink.lazyBroadcast(PatientEnrollmentInformationMounted()));

    return {
      enrollments: sink.select('patients.detail.summary.enrollments'),
      newEnrollment: sink.lazyBroadcast(EnrollPatientClicked()),
      editEnrollment: (surveyId, enrollmentId) =>
        sink.broadcast(EnrollmentEditClicked({ id: surveyId, enrollmentId })),
      editStatus: (surveyId, enrollmentId) => {
        sink.broadcast(
          EnrollmentChangeStatusClicked({ id: surveyId, enrollmentId }),
        );

        DialogInstance.open(EnrollmentChangeStatusDialogVue);
      },
    };
  },
});
