




















































import sink from '@/sink';
import { ActionCard } from '@conversa/bedazzled/src/cards';
import { InlineLoader } from '@/shared';
import { defineComponent, onMounted } from '@vue/composition-api';
import DetailCardRow from '../DetailCardRow.vue';
import { PatientProfileMounted } from './+state/events';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';
import EditPatientDialogVue from './EditPatientDialog.vue';

export default defineComponent({
  components: { ActionCard, DetailCardRow, InlineLoader },
  setup() {
    onMounted(sink.lazyBroadcast(PatientProfileMounted()));
    return {
      edit: () =>
        DialogInstance.open(EditPatientDialogVue, {
          title: 'Edit Patient Information',
        }),
      isLoadingPatient: sink.select('patients.detail.profile.loading'),
      details: sink.select('patients.detail.profile'),
      demographicCapabilities: sink.select('capabilities.demographics'),
      languages: sink.select('capabilities.languages'),
      canEdit: sink.select('capabilities.features.patientEdit'),
    };
  },
});
