













import sink from '@/sink';
import { ActionCard } from '@conversa/bedazzled/src/cards';
import { defineComponent } from '@vue/composition-api';
import CaregiverInformation from './caregiver/CaregiverInformation.vue';
import PatientProfile from './profile/PatientProfile.vue';
import PatientEnrollmentInformation from './enrollments/PatientEnrollmentInformation.vue';
import PatientSessionInformation from './sessions/PatientSessionInformation.vue';

export default defineComponent({
  components: {
    ActionCard,
    PatientProfile,
    PatientEnrollmentInformation,
    PatientSessionInformation,
    CaregiverInformation,
  },
  setup() {
    return {
      caregiverEnabled: sink.select('capabilities.features.caregiver'),
    };
  },
});
