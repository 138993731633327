





























import sink from '@/sink';
import { ActionCard, EmptyCase } from '@conversa/bedazzled/src/cards';
import { InlineLoader } from '@/shared';
import { defineComponent, onMounted } from '@vue/composition-api';
import DetailCardRow from '../DetailCardRow.vue';
import {
  CaregiverInformationMounted,
  CaregiverStatusClicked,
} from './+state/events';
import { patientsDetailSummaryCaregiverModule as m } from './+state/module';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';
import CaregiverCreateDialogVue from './dialogs/CaregiverCreateDialog.vue';
import CaregiverEditDialogVue from './dialogs/CaregiverEditDialog.vue';

export default defineComponent({
  components: { ActionCard, DetailCardRow, InlineLoader, EmptyCase },
  setup() {
    onMounted(sink.lazyBroadcast(CaregiverInformationMounted()));
    return {
      isLoadingPatient: m.injectStore().loading,
      caregiver: sink.select('patients.detail.summary.caregiver'),
      edit: () => {
        DialogInstance.open(CaregiverEditDialogVue);
      },
      addCaregiverClicked: () => {
        DialogInstance.open(CaregiverCreateDialogVue);
      },
      caregiverStatusClicked: sink.lazyBroadcast(CaregiverStatusClicked()),
    };
  },
});
