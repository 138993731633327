

























































import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  props: ['program'],
  emits: ['edit-enrollment', 'edit-status'],
  setup(props, ctx) {
    const showAnswers = ref(false);

    return {
      showAnswers,
      toggleAnswers() {
        showAnswers.value = !showAnswers.value;
      },
      editClicked: () =>
        ctx.emit('edit-enrollment', props.program.surveyId, props.program.id),
      changeEnrollmentStatus: () =>
        ctx.emit('edit-status', props.program.surveyId, props.program.id),
    };
  },
});
