






















import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent<{
  details: string | string[];
  type: string;
  label: string;
  preferred: boolean;
}>({
  props: ['details', 'type', 'label', 'preferred'],
  setup(props) {
    return {
      ariaLabel: computed(() => {
        return props.preferred
          ? `The preferred communication method ${props.label}: ${props.details}`
          : `${props.label}: ${props.details}`;
      }),
    };
  },
});
