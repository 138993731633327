













import sink from '@/sink';
import ScheduledChatsTable from '@conversa/bedazzled/src/table/chats/ScheduledChatsTable.vue';
import { defineComponent, onMounted } from '@vue/composition-api';
import {
  PatientResendChatLinkClicked,
  PatientSessionInformationMounted,
  SessionPageUpdated,
  ViewHistoricUpdated,
} from './+state/events';
import { patientsDetailSummarySessionsModule } from './+state/module';

export default defineComponent({
  components: { ScheduledChatsTable },
  setup() {
    onMounted(sink.lazyBroadcast(PatientSessionInformationMounted()));

    return {
      data: sink.select(
        'patients.detail.summary.sessions.paginated.current-set',
      ),
      pageNumber: sink.select('patients.detail.summary.sessions.page'),
      count: sink.select('patients.detail.summary.sessions.count'),
      activeProgramCount: sink.select(
        'patients.detail.summary.enrollments.active-count',
      ),
      viewHistoric: patientsDetailSummarySessionsModule.injectStore()
        .viewHistoric,
      updatePage: page => sink.broadcast(SessionPageUpdated({ page })),
      resend: sink.lazyBroadcast(PatientResendChatLinkClicked()),
      updateViewHistoric: ({ checked }) =>
        sink.broadcast(ViewHistoricUpdated({ viewHistoric: checked })),
    };
  },
});
